/***************************************************************************************************
 * Load `$localize` onto the global scope - used if i18n tags appear in Angular templates.
 */
import '@angular/localize/init';

/***************************************************************************************************
 * BROWSER POLYFILLS
 */

// Remove the line: import 'core-js/client/shim';
// Replace with:
import 'core-js/stable'; // Includes all stable ECMAScript features

import 'hammerjs/hammer';

/** Evergreen browsers require these. **/
// The following import is not typically needed with core-js v3 as it already covers ES6+ features
// import 'core-js/es6/reflect';

/***************************************************************************************************
 * Zone JS is required by Angular itself.
 */
import 'zone.js'; // Included with Angular CLI.

/***************************************************************************************************
 * APPLICATION IMPORTS
 */

(window as any)['global'] = window;
